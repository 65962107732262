<template>
   <div class='item' >
     <el-card
       :body-style="{padding:'0px'}"
       class="course-card">
       <div @click.stop='courseDetail(courseData)'>
         <img
           v-if="courseData.thumb"
           :src="courseData.thumb"
           :title='courseData.title'
           class="image" >
         <img v-else src="@/assets/images/default-course-cover.png" class="image" >
       </div>
       <div style="padding: 15px;">
          <el-tooltip :content="courseData.title" placement="top">
            <p
              @click.stop='courseDetail(courseData)'
              class="course-title">{{courseData.title}}</p>
          </el-tooltip>
          <p class="course-type">课程类型：{{courseData._type}}</p>
          <p class='course-btn'>
            <span class='price'>￥{{courseData.sale_price}}</span>
            <el-button
              :type="$config.buttonType"
              size="mini"
              plain
              @click.stop='courseDetail(courseData)'
              >课程详情</el-button>
          </p>
       </div>
     </el-card>
   </div>
</template>

<script>
  import {mapState,mapMutations} from 'vuex';
  import {isGou} from '@/api/course'
  export default{
    props:['courseData'],
    name:'courseItem',
    data(){
      return {

      }
    },
    computed:{
      ...mapState(['token'])
    },
    methods:{
      courseDetail(item){//课程详情
		this.$router.push({
			path:'/coursedetail/'+item.spec_id+'/'+item.comboclass_id + '/'+item.id
		})
      },
      courseCreateOrder(data){//创建订单
        if(this.token){
          isGou({
            product_id:data.id,
            type:data.courseType
          }).then(res=>{
            if(res.code==1){
              this.$notify({
                title: '该课程已报名成功',
                message: '请在个人中心查看学习',
                type: 'success'
              });
            }else{
              this.$router.push({
                path:'/createorder/'+ data.id + '/' + data.courseType
              })
            }
          });
        }else{
          this.$notify.error({
          	title: '未登录',
          	message: '请先登录',
          	duration:1500,
          	showClose:false
          });
          return;
        }
      }
    },
    mounted() {

    }
  }
</script>
<style scoped="scoped" lang="scss">
  .course-card{
    transition-property:all;
    transition-duration:0.5s;
    cursor: pointer;
    overflow: hidden;
    // padding:5px;
  }
  .course-card:hover{
    transform: translate(0px,-5px);
    -ms-transform: translate(0px,-5px);
    -webkit-transform: translate(0px,-5px);
    // box-shadow:0px 0px 4px 4px #aaa;
  }
  .course-card .course-title{

    width: 100%;
    font-size:16px;
    overflow: hidden;
    text-overflow:ellipsis;
    display: inline-block;
    white-space: nowrap;
  }
  .course-card .course-type{
    color:#888;
    font-size:12px;
    margin-top:5px;
  }
  .course-card .course-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top:10px;
  }
  .course-card .course-btn .price{
    font-size:18px;
    color:$price-color;
  }
  .image {
    width: 100%;
    display: block;
  }
</style>
