<template>
  <div class="teacher-card" @click.stop="goTeacherDetail(teacherData)">
    <div class="teacher-icon">
      <div class="img">
        <img :src="teacherData.thumb" alt="">
      </div>
      <div class="link">
        <h3>{{ teacherData.name }}</h3>
        <div style="text-align:center">
          <el-button 
			class="mt-1 button" 
			type="primary" 
			size="small"
			@click.stop='goTeacherDetail(teacherData)'>查看详情</el-button>
          <br><br>
        </div>
      </div>
    </div>
    <div class="teacher-info">
      {{stripHTML(teacherData.content)}}
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  props:['teacherData'],
  data() {
    return {

    }
  },
  components: {

  },
  methods:{
	goTeacherDetail(teacher) {
	    this.$router.push('/teacherdetail/' + teacher.id)
	},
    stripHTML(content){
      var regex = /(<([^>]+)>)/ig
      var result = content ? content.replace(regex, "") : ""
      return  result
    }
  }
}
</script>

<style scoped>
.teacher-card{
  display: flex;
  flex-direction: column;
  justify-content: start;
  width:98%;
  height:340px;
  cursor: pointer;
  overflow: hidden;
  border:1px solid #ddd;
  background-color: white;
  border-radius: 5px;
}
.teacher-icon{
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  height:240px;
  width:100%;
  overflow: hidden;
}
.teacher-icon .img{
  flex-basis: 60%;
  height: 100%;
  overflow: hidden;
  padding: 10px;
  padding-bottom: 0px !important;
}
.teacher-icon .link{
  flex-basis: 40%;
  display:flex;
  flex-direction: column;
  justify-content:flex-end;
  overflow: hidden;
}
.teacher-icon h3{
  width:100%;
  text-align: center;
  font-weight:bold;
  overflow: hidden;
}
.teacher-info{
  overflow: hidden;
  height:60px;
  line-height: 26px;
  padding: 20px;
  padding-top:5px !important;
} 
</style>