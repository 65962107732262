<template>
  <div>
    <!-- 头部导航 -->
    <el-affix :z-index='2001'>
      <nav-bar></nav-bar>
    </el-affix>
    <!-- 工具栏 -->
    <tool-box></tool-box>
	<!-- 广告轮播加课程类型菜单 -->
	<div class='swiper-wrap'>
		<div class='swiper'>
			<swiper
				:slides-per-view="1"
				:space-between="0"
				navigation
				:autoplay="{delay:3000,disableOnInteraction:false}"
				loop
				:pagination="{clickable:true}">
				<swiper-slide
					@click='adInfo(item)' 
					v-for='(item,index) in bannerList' 
					:key='index'>
					<li :title="item.title"
						:style="{backgroundImage:'url(\'' + item.thumb + '\')',
						backgroundRepeat:'no-repeat',
						backgroundPosition:'center center',
						height:'561px',
						width:screenWidth + 'px',
						cursor:'pointer'}">
					</li>
				</swiper-slide>
			</swiper>
		</div>
		<div 
			class='menus-swiper'
			:style="{left:screenWidth>1200?((screenWidth -1200)/2)+'px':'0px'}">
		    <ul class='menus'>
		      <li
		        class='menus-list'
		        :key='index'
		        v-for="(item,index) in menusList">
		        <span class="title">{{item.title}}</span>
		        <div class='sub-title'>
		          <el-row :gutter="10">
		            <el-col
		              :span='12'
		              v-for="(subItem,sIndex) in item.children"
		              :key='sIndex'>
		              <div
						@click='toSpecialPage(subItem)'
		                class='sub-con'>{{subItem.title}}</div>
		            </el-col>
		          </el-row>
		        </div>
		      </li>
		    </ul>
		</div>
	</div>
	
	<!-- 广告 -->
	<div class='course-ad'>
	  <div class='container'>
	    <div class='ad-title'>
	      <div class='title-top'>
	        <svg-icon icon-class='layer' class='ad-icon'></svg-icon>
	        <span>优质学习方案</span>
	        <svg-icon icon-class='layer' class='ad-icon'></svg-icon>
	      </div>
	      <span class='ad-des'>精品课程体系，满足不同阶段学习需求</span>
	    </div>
	    <el-row :gutter="20">
			<el-col
				:span="index < 2 ? 12 : 6"
				v-for="(item,index) in adImages"
				:key='index'>
				<div @click='adInfo(item)'>
					<img
					   class="ad-image"
					   :src="item.thumb"
					   :alt="item.title"
					   :style="{height:'100%',width:'100%'}"/>
				</div>
			</el-col>
	    </el-row>
	  </div>
	</div>
	
	<!-- 推荐课程 -->
	<div class='recommanded-list'>
	  <div class='main'>
	    <el-row>
	      <el-col 
			:key='index' 
			v-for="(item,index) in recommandedCourses" >
	        <!-- 推荐课程标题 -->
	        <div class="list-title">
				<div class='title'>
					<svg-icon icon-class='rectitle1' class='title-icon'></svg-icon>
					<span> {{item.title}}</span>
					<svg-icon icon-class='rectitle2' class='title-icon'></svg-icon>
				</div>
				<div @click='toCourseList(item)' class='more'>
					<svg-icon icon-class='youbian' class='more-icon'></svg-icon>
				</div>
	        </div>
	        <div class="recommanded-course-list">
	          <el-row :gutter="10">
	            <el-col :span="6"
					  :key='mindex'
					  v-for="(mitem,mindex) in item.combo"
					  class="mt-1">
	              <course-item
						:course-data='mitem'
						v-if='mitem'></course-item>
	            </el-col>
	          </el-row>
	        </div>
	      </el-col>
	    </el-row>
	  </div>
	</div>
	
	<!-- QQ广告 -->
	<div class='ad-qq'>
	  <ul>
	    <li v-for='(item,index) in qqAds' :key='index' class='qq-item'>
	      <img :src="item.thumb" alt="">
	      <div class='desc'>
	        <svg-icon icon-class='qq'></svg-icon>
	        <router-link to='' @click='navtoqq'>
	          {{item.title}}
	        </router-link>
	      </div>
	    </li>
	  </ul>
	</div>
	
	<!-- 在线题库 -->
	<div class='recommanded-list'>
	  <div class='container'>
	    <el-row>
	      <el-col>
	        <!-- 推荐课程标题 -->
	        <div class="list-title">
				<div class='title paper-title'>
					<svg-icon icon-class='linianzhenti' class='title-icon'></svg-icon>
					<span> 在线题库</span>
					<svg-icon icon-class='linianzhenti' class='title-icon'></svg-icon>
				</div>
				<div @click='toPaperBank()' class='more'>
					<svg-icon icon-class='youbian' class='more-icon'></svg-icon>
				</div>
	        </div>
	        <div class="paper-item">
	          <el-card 
				  class='box-card' 
				  v-if='examBanks.length>0' style='padding:20px'>
	            <el-table
					  :data="examBanks"
					  border
					  style="width: 100%">
	              <el-table-column
	                label="题库名称"
	                width="600">
	                <template #default="scope">
	                  <div class='paper-title'>
	                    <span>{{scope.row.name }}</span>
	                  </div>
	                </template>
	              </el-table-column>
	              <el-table-column
	                prop="lowprice"
	                width="180"
	                label="优惠价">
	                <template #default="scope">
	                  <div class='lowprice'>
	                    ￥{{scope.row.sale_price }}
	                  </div>
	                </template>
	              </el-table-column>
	              <el-table-column
	                width="180"
	                label="推荐指数">
	                <el-rate
						v-model="rateVal"
						disabled
						:colors="['#f00', '#f00', '#f00']"></el-rate>
	              </el-table-column>
	              <el-table-column
	                label="详情">
	                <template #default="scope">
	                 <div class='handle'>
	                   <el-button
	                     size="mini"
	                     plain
	                     style='font-size:12px;'
	                     @click='toComboPaperList(scope.row)'
	                     :type='$config.buttonType'>进入练习</el-button>
	                 </div>
	                </template>
	              </el-table-column>
	            </el-table>
	          </el-card>
	        </div>
	      </el-col>
	    </el-row>
	  </div>
	</div>
	
	<!-- 推荐讲师 -->
	<div class='teachers-wrap'>
	  <div class='container'>
	    <el-row>
	      <el-col>
	        <div class="list-title">
	        	<div class='title teacher-title'>
	        		<svg-icon icon-class='laoshi' class='icon-laoshi'></svg-icon>
	        		<span>行业导师</span>
	        		<svg-icon icon-class='laoshi' class='icon-laoshi'></svg-icon>
	        	</div>
	        	<div class='more'>
	        		<svg-icon icon-class='youbian' class='more-icon'></svg-icon>
	        	</div>
	        </div>
	        <div class='teacher-swiper'>
				<swiper
					:slides-per-view="1"
					:space-between="0"
					navigation
					:autoplay="false"
					loop>
					<swiper-slide
						v-for='(item,index) in swiperPages'
						:key='index'>
						<el-row :span='24' :gutter="10">
							<el-col
								class='swiper-list'
								:span="6"
								v-for='(list,lindex) in item'
								:key='lindex'>
								<teacher-item :teacher-data='list'></teacher-item>
							</el-col>
						</el-row>
					</swiper-slide>
				</swiper>
	        </div>
	      </el-col>
	    </el-row>
	  </div>
	</div>
	
	<!-- app广告 -->
	<div class='ad-app'>
	  <ul>
	    <li v-for='(item,index) in appAds' :key='index' class='app-item'>
	      <img :src="item.thumb" alt="">
	      <div class='desc'>
	        <router-link to=''>
	          {{item.title}}
	        </router-link>
	      </div>
	    </li>
	  </ul>
	</div>
	<!-- footer -->
	<web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import {getCurrentInstance} from 'vue'
import NavBar from '@/components/page/NavBar'
import ToolBox from '@/components/toolbox'
import WebFooter from '@/components/page/WebFooter'
import courseItem from '@/views/course/components/ComboClassItem1'
import teacherItem from '@/views/teacher/components/TeacherItem'

import SwiperCore, { 
		Navigation, 
		Pagination, 
		Scrollbar, 
		A11y, 
		EffectFade, 
		Autoplay } from 'swiper';
import {Swiper,SwiperSlide} from 'swiper/vue'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]);	

import {
		getBanner,
		getMenus,
		getAdvertSpec,
		getAdvertCourse,
		getAdvertQQ,
		testBank,
		getTeacher,
		getAdvertApp
	} from '@/api/index1'
	
import { setSessionStorage, getSessionStorage } from '@/utils/auth'

export default {
  data() {
    return {
      bannerList:[],
	  menusList:[],
	  screenWidth:'',
	  screenHeight:'',
	  adImages:[], 
	  recommandedCourses:[],
	  qqAds:[],
	  examBanks:[],
	  rateVal:5,
	  teachers:[],
	  appAds:[]
    }
  },
  components: {
    NavBar,
    ToolBox,
    Swiper,
    SwiperSlide,
    WebFooter,
	courseItem,
	teacherItem
  },
  computed:{
    swiperPages (){
      let pages = [];
      this.teachers.forEach((item, index)=>{
        let pagesIndex = Math.floor(index/4);
        if(!pages[pagesIndex]){
          pages[pagesIndex] = [];
        }
        pages[pagesIndex].push(item);
      });
      return pages;
    }
  },
  created(){
    const {proxy} = getCurrentInstance()
	//获取轮播图
	getBanner().then(res=>{
	  if(res.code==1){
	    this.bannerList = res.data;
	  }
	});
	//获取导航菜单
	getMenus().then(res=>{
	  if(res.code==1){
	    this.menusList = res.data;
	  }
	});
	//获取广告
	getAdvertSpec().then(res=>{
	  if(res.code==1){
	    this.adImages = res.data;
	  }
	});
	//推荐课程
	getAdvertCourse().then(res=>{ 
	  if(res.code==1){
	    this.recommandedCourses = res.data;
	  }
	});
	//获取广告位QQ
	getAdvertQQ().then(res=>{
	  if(res.code==1){
	    this.qqAds = res.data;
	  }
	});
	testBank().then(res=>{ //推荐题库
	  if(res.code==1){
	    this.examBanks = res.data.slice(0,10);
	  }
	});
	getTeacher().then(res=>{ //推荐老师
	  if(res.code==1){
	    this.teachers = res.data;
	  }
	});
	getAdvertApp().then(res=>{//app
	  if(res.code==1){
	    this.appAds = res.data;
	  }
	});
	//检测屏幕尺寸
	this.registerWindowResize()
  },
  methods:{
	  adInfo(list){//轮播图广告跳转
	  	if(list.type == 1){//海报
	    
				
	  	}
	  	if(list.type == 2){//课程列表
	  		let idArr = list.urlId.split(",");
	  		this.$router.push({
	  		  path:'/course/' + idArr[0] + '/'+idArr[1]
	  		});
	  	}
	  },
	  toSpecialPage(item){
	  	this.$router.push('/special/' + item.id)
	  },
	  toCourseList(item){//课程列表
		  this.$router.push({
		  	path:'/course/'+item.pid+'/'+item.id
		  })
	  },
	  navtoqq () {
			window.location.href = 'http://wpa.qq.com/msgrd?v=3&uin=1034127667&site=qq&menu=yes'
	  },
	  toPaperBank(){//更多题库
	    this.$router.push({
	      path:'/exam'
	    })
	  },
	  toComboPaperList(data){//题库详情
	    setSessionStorage("currentTestGood", data)
	    this.$router.push('/secretdetail')
	  },
	  registerWindowResize(){
	    this.screenWidth = document.body.clientWidth;
	    this.screenHeight = document.body.clientHeight;
	    window.onresize = () => {
	      return (() => {
	        if(document.body.clientWidth > 1920){
	          this.screenWidth = 1920
	        }else if(document.body.clientWidth < 1200){
	          this.screenWidth = 1200
	        }else{
	          this.screenWidth = document.body.clientWidth;
	        }
	        this.screenHeight = document.body.clientHeight;
	      })();
	    };
	  }
  }
}
</script>

<style scoped lang="scss">
	//轮播图
	.swiper-wrap{
	   width:100%;
	   height:561px;
	   overflow: hidden;
	   position: relative;
	   .menus-swiper{
	     position: absolute;
	     left:0px;
	     top:0px;
	     z-index: 99;
		 .menus{
		   width:300px;
		   height:561px;
		   overflow: hidden;
		   box-sizing: border-box;
		   padding:10px 10px;
		   background:rgba(255,255,255,0.7);
		   display: flex;
		   flex-direction: column;
		   .menus-list{
		     .title{
		       font-size:18px;
		       font-weight:bold;
			   line-height: 32px;
		     }
		     .sub-title{
		       font-size:14px;
		       .sub-con{
				padding:5px 0px;
		        cursor: pointer;
		       }
		       .sub-con:hover{
		         color:$theme-color;
		       }
		     }
		   }
		 }
	   }
	}

	/* 广告 */
	.ad-title{
	  width:100%;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  padding:20px 0px;
	}
	.ad-title .title-top{
	  font-size:24px;
	}
	.title-top .ad-icon{
	  padding:0px 20px;
	  color:$theme-color;
	  font-weight: bold;
	}
	.ad-title .ad-des{
	  margin-top:10px;
	  font-size:18px;
	}
	.ad-image{
	  border-radius:5px;
	  transition-property: all;
	  transition-duration: .5s;
	  margin:10px 0px;
	  cursor: pointer;
	  transition-property:all;
	  transition-duration:0.5s;
	}
	.ad-image:hover{
	    box-shadow:0px 0px 4px 4px #aaa;
	}
	
	/* 推荐课程 */
	.list-title{
	  border-bottom: $theme-color solid 3px;
	  line-height:70px;
	  margin-top:30px;
	  font-size:24px;
	  color:#333;
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  justify-content: center;
	  position: relative;
	  .title{
			.title-icon{
		  		font-size:45px;
		  		padding:0px 30px;
		  		color:$theme-color;
			}
	  }
	  .paper-title{
		 .title-icon{
		 	color:#333;
		 	font-size:26px;
		 	padding:0px 30px;
		 	color:$theme-color;
		 	justify-self: flex-end;
		 }
	  }
	  .more{
		 position: absolute;
		 right:10px;
		 bottom:-10px;
		 .more-icon{
		   font-size:18px;
		   cursor: pointer;
		   color:#aaa;
		 }
		 .more-icon:hover{
			color:$theme-color;
		 }
	  }
	}
	
	.recommanded-course-list .course-card{
	  transition-property:all;
	  transition-duration:0.5s;
	  cursor: pointer;
	  overflow: hidden;
	  padding:5px;
	}
	.recommanded-course-list .course-card:hover{
	  transform: translate(0px,5px);
	  -ms-transform: translate(0px,5px);
	  -webkit-transform: translate(0px,5px);
	  box-shadow:0px 0px 4px 4px #aaa;
	}
	.recommanded-course-list .course-title{
	  display: inline-block;
	  white-space: nowrap;
	  width: 100%;
	  overflow: hidden;
	  text-overflow:ellipsis;
	}
	.image {
	  width: 100%;
	  display: block;
	}
	
	
	/* qq客服广告位 */
	.ad-qq{
	  padding:20px 0px;
	}
	.ad-qq ul{
	  display: flex;
	  flex-direction: column;
	}
	.ad-qq ul li{
	  position: relative;
	}
	.ad-qq ul li img{
	  height:100%;
	  width:100%;
	}
	.ad-qq ul li .desc{
	  position: absolute;
	  right:100px;
	  top:50%;
	  margin-top:-20px;
	  width:200px;
	  height:30px;
	  border-radius: 30px;
	  text-align: center;
	  line-height: 30px;
	  border:1px solid #fff;
	  color:#fff;
	  cursor: pointer;
	}
	.ad-qq ul li .desc a{
	   color:#fff;
	}
	
	
	/* 在线题库 */
	.paper-item{
	  margin-top:20px;
	}
	.paper-item >>> th{
	  text-align: center!important;
	  font-size:16px;
	  font-weight: normal;
	}
	.paper-item .paper-title{
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	}
	.paper-item .paper-title img{
	  width:180px;
	  height: auto;
	  margin-right:20px;
	}
	.paper-item .lowprice{
	  font-size:16px;
	  color:$price-color;
	}
	
	
	/* 推荐讲师 */
	.teacher-title{
		.icon-laoshi{
			font-size:35px;
			padding:0px 30px;
			color:$theme-color;
		}
	}
	.teacher-swiper{
		width:100%;
		overflow: hidden;
		margin:20px auto;
		padding:0px 5px;
	}
	.swiper-container{
		--swiper-theme-color:#aaa;/* 设置Swiper风格 */
		--swiper-navigation-color:#aaa;/* 单独设置按钮颜色 */
		--swiper-navigation-size:40px;/* 设置按钮大小 */
	}
	.swiper-button-prev, .swiper-button-next{
		border:1px solid blue!important;
	}
	
	
	/* app广告 */
	.ad-app ul{
	  display: flex;
	  flex-direction: column;
	}
	.ad-app ul li{
	  position: relative;
	}
	.ad-app ul li img{
	  height:100%;
	  width:100%;
	}
	.ad-app ul li .desc{
	  position: absolute;
	  left:100px;
	  top:50%;
	  margin-top:-25px;
	  text-align: center;
	  font-size:20px;
	  color:#fff;
	  cursor: pointer;
	  font-size:28px;
	}
	.ad-app ul li .desc a{
	   color:#fff;
	}
</style>